@import '~themify-icons-sass/themify-icons/_themify-icons';
@import '~admin-lte/dist/css/AdminLTE';
@import '~admin-lte/dist/css/skins/_all-skins';

$bleu: #20aee3;
$gris: #8d97ad;
$fonce: rgba(0, 0, 0, 0.7);
$txt-fonce: #263238;
$border: rgba(120, 130, 140, 0.13);
$rouge: rgb(255, 92, 108);
$bg-clair: #f6f9fa;
$txt-clair: #bcc3d3;
$vert: #24d2b5;
$orange: #ff9041;
$dark: #2f3d4a;
$purple: #6772e5;
$gris-select:#495057;
$bleu-marine:#0275d8;
$txt-chart:#67757c;

body {
  background-color: white;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overflow:hidden;

  .sonata-ba-list-field-text {
    .label.bg-purple.bg-special-twice {
      &:nth-child(2) {
        display:none;
      }
    }
  }

  .form-control:focus {
    border-color:$bleu;
  }

  .card-blue {
    background:$bleu;
    margin-top:0 !important;
    padding:15px 20px;

    .select2-container .select2-choice, .select2-container .select2-choices, .select2-container .select2-choices .select2-search-field input {
      border:0 !important;
    }

    .card-title, .card-subtitle {
      color:white;
    }

    .card-subtitle {
      opacity: 0.5;
    }

    .info-container {
      padding:12px;
      padding-top:15px;
      color:white !important;
      font-weight:500;

      label {
        font-weight:300;
      }
    }
  }

  section.content {
    & > .sonata-ba-form {
      box-shadow: 0 3px 12px rgba(0, 0, 0, .04);
      background:white;
      padding:15px;

      .box.box-primary {
        box-shadow:none !important;
      }
    }
  }

  .d-none {
    .box-title {
      display:none;
    }
  }

  .d-none-thing {
    display:none !important;
  }

  .box-bg-white {
    background:white;
    padding:15px;
  }

  .modal-body {
    .navbar.navbar-default.sonata-list-table {
      display:none !important;
    }

    .box .box-body.table-responsive {
      padding:0 !important;
    }

    .sonata-ba-list-field-select {
      .btn.btn-primary {
        border:0;
        background: $vert !important;
        color: white !important;
        padding: 7px 12px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 15px;
        transition: 0.4s all ease;
        box-shadow:0 !important;
        outline:none !important;


        &:hover {
          opacity:.75;
        }
      }
    }
  }

  .sonata-ba-delete {
    .box {
      padding:15px;

      .btn {
        font-size:16px !important;
        padding:7px 12px !important;
        border-radius: 4px;
        border: 0;
        color: white !important;
        padding: 8px 12px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        transition: 0.4s all ease;
        box-shadow: 0 !important;
        outline: none !important;

        &:hover {
          opacity:.75;
        }
      }
    }
  }

  &.login-page {
    background-size:cover;
    background-color: white;

    .form-control-feedback {
      top:5px;
      font-size:18px;
    }

    .login-logo {
      img {
        max-width:300px;
      }
    }

    .login-box-msg {
      font-size:21px;
      color:$fonce;
      padding:0;
      padding-left:2px;
      padding-bottom:40px;
      text-align:left;
    }

    .btn-primary {
      background:$bleu !important;
      border-radius:40px;
      border:0;
      padding:15px;
      transition:0.4s all ease;
      margin-top:12px;
      margin-bottom:30px;
      outline:none !important;
      box-shadow:0 !important;

      &:hover {
        opacity:.75;
      }
    }

    .small-copy {
      font-size:11px;
      padding-top:32px;
      display:inline-block;

      a {
        color:$bleu;
        transition:all 0.3s ease;

        &:hover {
          color:$fonce;
        }
      }
    }
  }
}

.main-header {
  .navbar {
    background-color: white !important;
    border-bottom: 1px solid $border;
    display:flex;
    justify-content:space-between;

    &:before, &:after {
      display:none;
    }
  }
  .logo {
    background-color: white !important;
    border-bottom: 0 !important;
    border-right: 1px solid $border;
    width: 260px;
  }
}

.content-wrapper {
  & > .content {
    overflow-x:visible;
  }
}

.content-wrapper {
  background: $bg-clair;
  overflow-x:auto !important;
  overflow-y:scroll !important;

  @media screen and (max-width: 768px) {
    margin-left: 0px !important;
  }

  @media screen and (min-width: 768px) {
    overflow-y: scroll;
    width: calc(100% - 260px);
    min-height:calc(100vh - 152px) !important;
    height:calc(100vh - 74px) !important;
    padding-bottom:74px;
  }

  & > .nav.navbar-top-links.breadcrumb, & > .content-header, & > .content {
    min-width:1100px;
  }
}

section.content {
  overflow-x:hidden;
  overflow-y:visible;
  min-height: 400px;
}

.main-sidebar {
  background-color: #fff !important;
  border-right: 1px solid $border;
  overflow: -moz-scrollbars-none;
}

@media screen and (max-width: 767px) {
  .main-sidebar {
    transform: translate(-260px, 0);
  }
}

.sidebar-menu {
  > li {
    &:hover > a {
      color: $bleu;
      background: #ffffff;
    }
    &.active > a, &.menu-open > a {
      color: $bleu !important;
      background: #ffffff !important;
      font-weight: 500 !important;
    }
    > a {
      position: relative;
      overflow: hidden;
      &:hover {
        background: white !important;
        color: $bleu !important;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background: rgba(0, 0, 0, 0.15);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }
      &:focus:not(:active)::after {
        animation: ripple .6s ease-out;
      }
    }
  }
  li > a > .pull-right-container {
    font-size: 20px;
    color: $gris;
  }
}

.skin-blue .sidebar-menu > li > a {
  padding: 6px 5px 10px 15px;
  margin-top: 10px;
  i {
    margin-right: 18px;
    font-size: 24px;
    width: 20px;
    display: inline-block;
  }
}

.sidebar-menu li > a > {
  .fa-angle-left, .pull-right-container > .fa-angle-left {
    font-size: 20px !important;
  }
}

.skin-blue .sidebar-menu > li > .treeview-menu {
  margin-top: 8px;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(80, 80);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ripple-small {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.0;
  }
}

.sidebar-menu {
  > li {
    &.header {
      background: #ffffff !important;
      color: #aab0bd !important;
      font-weight: 500 !important;
    }
    > a {
      transition: all 0.6s ease;
    }
    &.active > a, &.menu-open > a {
      border-left-color: $bleu !important;
    }
  }
  .treeview-menu > li {
    &.active {
      > a {
        background: white !important;
        color: $gris !important;
      }
      > a i {
        display: none;
      }
      > a {
        color: $bleu !important;
        font-weight: 500;
        font-size: 15px;
      }
    }
    > a {
      background: white !important;
      color: $gris !important;
      &:hover {
        background: white !important;
        color: $gris !important;
      }
    }
    > a {
      i {
        display: none;
      }
      &:hover {
        i {
          display: none;
        }
        color: $bleu !important;
      }
    }
  }
}

.timeline-panel {
  position: relative;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  margin-bottom:16px;

  .btn-warning {
    background:$orange;
    border:0 !important;
    border-radius:4px;
    color:white !important;
    transition:0.4s all ease;

    &:hover {
      opacity:0.75;
    }
  }

  h3 {
    color: $txt-chart;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 22px;
    font-size: 14px;
    margin-top:0;

    span {
      font-weight:400;
    }
  }

  .timeline-title {
    margin-top: 0;
    color: $txt-chart;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 22px;
    font-size: 18px;
  }

  .text-muted {
    color:#bcc3d3;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }

  .timeline-body {
    font-weight:300;
    color:$txt-chart;
    font-family: "Montserrat", sans-serif;
  }
}

.skin-blue .sidebar a {
  color: $gris;
  font-size: 15px !important;
}

.sidebar-menu > li > .treeview-menu {
  background: white !important;
  padding-left: 40px;
}

.main-header .sidebar-toggle {
  color: $fonce !important;
  padding: 18px 20px;
  font-size: 26px;
  background: white !important;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  &:focus:not(:active)::after {
    animation: ripple-small .7s ease-out;
  }
}

.main-sidebar {
  width: 260px;

  @media screen and (min-width: 768px) {
    max-height:calc(100vh - 50px);
    overflow-y:auto;
  }
}

.main-header .navbar {
  margin-left: 260px;
  min-height: 70px;
}

@media screen and (max-width: 767px) {
  .main-header .navbar {
    margin-left: 0px !important;
  }
}

.content-wrapper, .main-footer {
  margin-left: 260px;
  margin-top: 20px;
}

.main-footer {
    margin-top: 20px;
    position: absolute !important;
    width: calc(100% - 260px);
    bottom: 0px;
    left: 0 !important;
    display: block;
    position:fixed;
}

.sidebar-mini.sidebar-collapse {
  .main-sidebar {
    width: 66px !important;
    padding-top: 86px;
    overflow:visible !important;
  }
  .main-header .navbar {
    margin-left: 66px !important;
  }

  .sidebar-menu > li.treeview {
    height:44px !important;

    a {
      max-height:44px !important;
    }
  }
}

.ps__thumb-y, .ps__thumb-x {
  opacity:0.8;
  background-color:#aaa !important;
}

.main-sidebar {
  .ps__thumb-y {
    opacity:0.5;
    background-color:#aaa !important;
    left: -244px;
    right:auto;
  }
}

.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y {
  background-color:#aaa !important;
}

.ps__rail-x, .ps__rail-y {
  background: transparent !important;
}

@media screen and (max-width: 767px) {
  .sidebar-mini.sidebar-collapse .main-sidebar {
    transform: translate(-260px, 0);
  }
}

.main-header .sidebar-toggle:before {
  display: none;
}

.skin-blue .sidebar-form {
  margin: 10px 15px;

  input[type="text"] {
    background-color: white !important;
    background: white !important;
    background-image: linear-gradient($bleu, $bleu), linear-gradient(#e9edf2, #e9edf2) !important;
    border: 0 none;
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    background-position: center bottom, center calc(100% - 1px) !important;
    background-repeat: no-repeat !important;
    background-size: 0 2px, 100% 1px !important;
    transition: background 0.4s ease !important;
    padding-left:2px;
  }
  border: 0 !important;
  margin-bottom: 46px;
  margin-top: 42px;
  .btn {
    background: white !important;
    background-image: linear-gradient($bleu, $bleu), linear-gradient(#e9edf2, #e9edf2) !important;
    border: 0 none;
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    background-position: center bottom, center calc(100% - 1px) !important;
    background-repeat: no-repeat !important;
    background-size: 0 2px, 100% 1px !important;
    transition: background 0.4s ease !important;
  }
  input[type="text"]:focus, .btn:focus {
    background-size: 100% 2px, 100% 1px !important;
    outline: 0 none;
    transition-duration: 0.3s;
  }
}

.login-box-body {
  box-shadow: 0 3px 12px rgba(0, 0, 0, .04);
  input[type="text"], input[type="password"] {
    background-color: white !important;
    background: white !important;
    background-image: linear-gradient($bleu, $bleu), linear-gradient(#e9edf2, #e9edf2) !important;
    border: 0 none;
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    background-position: center bottom, center calc(100% - 1px) !important;
    background-repeat: no-repeat !important;
    background-size: 0 2px, 100% 1px !important;
    transition: background 0.4s ease !important;
    padding-left:2px;
    font-size:16px;
    margin-bottom:32px;
  }
  border: 0 !important;
  margin-bottom: 46px;
  margin-top: 42px;
  input[type="text"]:focus,
  input[type="password"]:focus {
    background-size: 100% 2px, 100% 1px !important;
    outline: 0 none;
    transition-duration: 0.3s;
  }
}

.navbar-nav > .user-menu .user-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
}

.skin-blue .main-header .navbar .nav > li > a {
  color: $fonce !important;
  font-weight: 300;
}

.navbar-nav > li > a {
  padding-top: 23px;
  padding-bottom: 22px;
  border-left: 1px solid $border !important;
  background: white !important;
  transition: 0.4s all ease;
  position: relative;
  overflow: hidden;
  &:hover {
    color: black !important;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  &:focus:not(:active)::after {
    animation: ripple .7s ease-out;
  }
}

@media screen and (max-width: 991px) {
  .navbar-nav > li > a {
    padding-top: 23px !important;
    padding-bottom: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .navbar-nav > li > a {
    padding-top: 29px !important;
    padding-bottom: 22px !important;
  }
}

.logo span {
  color: $bleu;
  font-weight: 300;
  margin-left: 6px;
  b {
    font-weight: 400;
  }
}

.skin-blue .main-header .logo {
  text-align: left;
  margin-top:6px;
  padding-left: 24px;

  @media screen and (min-width: 768px) {
    height: 74px;
    padding-bottom: 12px;
    padding-top: 14px;
    margin-top:0;
  }
}

@media screen and (max-width: 767px) {
  .skin-blue .main-header .logo {
    display: none !important;
  }
}

.animated {
  animation-duration: 0.7s !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sidebar-mini.sidebar-collapse {
  .logo img {
    margin-left: -15px;
  }
  .main-header .logo {
    border: 0 !important;
    width: 64px !important;
  }
  .sidebar-menu > li > {
    a i {
      margin-right: 50px;
    }
    .treeview-menu {
      padding-left: 12px;
    }
  }
}

.skin-blue .sidebar-menu {
  .treeview-menu > li > a {
    margin-bottom: 6px;
  }
  > li {
    &:hover > a, &.active > a, &.menu-open > a {
      color: $bleu;
      background: #ffffff;
    }
  }
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > {
  a > span:not(.pull-right), .treeview-menu {
    display: block !important;
    position: absolute;
    width: 256px;
    left: 65px;
    top: -10px !important;
    border-top: 1px solid $border;
    border-right: 1px solid $border;
    border-bottom: 1px solid $border;
  }
}

.skin-blue .main-header li.user-header {
  background-color: white;

  .img-circle {
    height:82px;
    width:100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width:768px) {
      height:37vw;
    }
  }
}

.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  width: 260px;
  border-top: 1px solid $border;
  top: 75px;
  border-radius: 4px !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  > .user-body {
    border-top-color: $border;
    a {
      padding: 9px 15px;
      color: $fonce;
      font-size: 16px;
      font-weight: 300;
      i {
        font-size: 19px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    right: 0px !important;
  }
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: auto;
  img {
    max-width: 100%;
    border-radius: 6px !important;
  }
  h4 {
    font-size: 15px;
    font-weight: 300 !important;
    color: $fonce;
    margin-bottom: 4px;
  }
  .text-muted {
    color: $txt-clair !important;
    font-size: 12px;
  }
}

.btn-rounded {
  border-radius: 60px;
}

.btn-danger {
  background-color: #ff5c6c !important;
  border-color: #ff5c6c !important;
  transition:0.4s all ease;

  &:hover {
    opacity:0.75;
  }
}

.skin-blue {
  .wrapper, .main-sidebar, .left-side {
    background-color: transparent;
  }
  .wrapper {
    background-color: $bg-clair;

  @media screen and (min-width: 768px) {
      max-height: 100vh;
      min-height: 100vh !important;
      overflow: hidden;
    }
  }
}

.avancement_progress {
  margin-top:-20px;
  margin-bottom:12px;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: auto;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;


  .progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: $bleu;
    transition: width .6s ease;
  }
}

.breadcrumb {
  background-color:transparent;
  margin-bottom:0;
  padding-top:10px;
  padding-bottom:0px;

  &.nav>li>a,
  &.nav>li>span {
    display:inline-block;
    color:$gris;
    text-transform: uppercase;
    font-size:12px;
    padding:0;
    margin-top:4px;
    transition:all 0.3s ease;

    &:hover {
      color:$bleu;
    }
  }

  &.nav>li>span {
    color:$txt-fonce;

    &:hover {
      color:$txt-fonce;
    }
  }

  &>li+li:before {
    padding: 0 5px;
    color: $gris;
    content: "\e876";
    font-family: Linearicons-Free;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
  }

  .title-page-container {
    padding-left:10px;
    padding-right:10px;

    @media screen and (max-width: 520px) {
      margin-bottom: -120px !important;
    }

    h3 {
      color:$bleu;
      font-size:21px;
      font-weight:400;
      font-family:'Montserrat', sans-serif;
    }

    @media screen and (max-width: 991px) {
      .text-right {
        padding-top:0 !important;
        margin-bottom:0 !important;
      }
    }

    .text-right {
      @media screen and (max-width: 580px) {
        margin-bottom:-54px !important;
        a.btn-create {
          margin-bottom: -15px;
          margin-top: 20px;
          width: calc(100%);
          /* margin-right: -10px; */
          display: block;
          text-align: left;
          padding: 11px 15px;
          font-size: 18px;
          font-weight: 400;
          font-family: 'Source Sans Pro', sans-serif;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-open .content-wrapper, .sidebar-open .main-footer {
      transform: translate(260px, 0);
  }
}

@media screen and (max-width: 580px) {
  .navbar-top-links li {
    visibility:hidden;
  }
}


.navbar-default {
  border:0;
  background-color:white;
  border-radius:0;
  box-shadow:none;
}

.list-unstyled {
  .checkbox {
    margin-top:0;
    margin-bottom:0;
  }
}

.field-container {
  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding-bottom:0;
  }

  .table thead th {
    color: $txt-chart;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 8px !important;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 0px;
  }

  .table-credit-temps {
    td {
      font-weight:300;
      color:#4c575d;
    }
  }

  .table-bordered.table-technique {
    .btn-warning.btn-sm.sonata-ba-action {
      margin-bottom:6px;
    }

    tbody {
      td {
        font-weight:300;

        .checkbox {
          margin-top:4px;
          margin-bottom:0;
        }

        &.bg-orange-accreditation {
          background:#ffe3cc !important;
        }

        &.bg-bleu-accreditation {
          background:#ccebff !important;
        }

        &.bg-rouge-accreditation {
          background:#ffcccc !important;
        }
      }
    }
  }
}

tbody, tfoot {
  td, th {
    font-weight:300;

    .checkbox {
      margin-top:4px;
      margin-bottom:0;
    }

    &.bg-vert-rq {
      background:#dff7e4 !important;
    }

    &.bg-turquoise-rq {
      color:white !important;
      font-weight:500 !important;
      background:rgba(0, 213, 180, 0.7) !important;
    }

    &.bg-orange-rq {
      background:#ffe3cc !important;
    }

    &.bg-bleu-rq {
      background:#ccebff !important;
    }

    &.bg-bleu-clair-rq {
      background:rgba(229, 247, 255, 0.65) !important;
    }

    &.bg-rouge-rq {
      background:#ffcccc !important;
    }
  }
}

.dataTables_filter {
  min-width:300px;
  margin-top:-54px;

  input {
    margin-left:0 !important;
    min-width:300px;
  }
}

.btn-create, .btn-toggle {
  background:$bleu !important;
  color:white !important;
  padding:7px 12px;
  border-radius:4px;
  font-weight:400;
  font-size:16px;
  transition:0.4s all ease;
  outline:none !important;
  box-shadow:0 !important;

  &:hover {
    opacity:.75;
  }
}

.btn-toggle {
  outline:none !important;
  width:100%;
  box-shadow:none !important;
  font-family: 'Montserrat';
  font-weight:400;

  &:hover {
    opacity:1;
  }
}

.card-toggle {
  margin-top: 12px;

  h5 {
    margin:0;
    padding-left:0;
  }
}

.table-offres {
  thead, tfoot {
    th {
      color:$txt-chart;
      font-weight:500;
    }
  }
}

.accordion {
  .card-body {
    padding:12px;
    border-left: 2px solid $bleu;
    border-right: 2px solid $bleu;
    border-bottom: 2px solid $bleu;
  }
}

.table-maquettes {
  thead {
    th {
      color:$txt-chart;
      font-weight:600;
      font-family: 'Montserrat', sans-serif;
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        color:$txt-chart;
        font-weight:300;

        .form-group {
          margin-bottom:0;
        }
      }
    }
  }
}

.content-header {
  margin-top:-15px;
}

.sonata-ba-collapsed-fields {
  .box-primary {
    overflow:hidden;
  }
}

.box {
  border-radius:0;
  box-shadow:none;
  margin-bottom: 26px;

  &.box-primary, &.box-user-list {
    border:0;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .04);
  }

  .box-footer {
    border-top:0px solid $bg-clair;

    hr {
      display:none;
    }

    .pull-left {
      label {
        margin-right:10px;
        font-weight:500;
        margin-left:10px;
      }

      .btn {
        background:$bleu !important;
        border-radius:4px !important;
        border:0;
        height:40px;
        font-weight: 400;
        font-size: 16px;
        transition:all 0.4s ease;
        outline:none !important;
        box-shadow:0 !important;

        &:hover {
          opacity:.75;
        }
      }
    }

    .pull-right {
      margin-right:10px;
      font-weight: 300;

      .control-label {
        font-weight:500;
      }

      .btn {
        background:$bleu;
        border-radius:4px !important;
        border:0;
        height:40px;
        font-weight: 400;
        font-size: 16px;
        color:white;
        transition:all 0.4s ease;

        &:hover {
          opacity:.75;
        }

        .fa-share-square-o {
          &:before {
            content:"\e867";
            font-family: Linearicons-Free;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
          }
        }
      }
    }


    .dropdown-menu > li > a {
      color:$gris-select;
      opacity: 0.9;
      font-weight: 400;
      padding-left: 18px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 0;

      &:hover {
        background:#20ade31c;
      }

      .fa-arrow-circle-o-down {
        &:before {
          content:"\e876";
          font-family: Linearicons-Free;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          font-size: 9px;
        }
      }
    }
  }

  .box-body {
    &.table-responsive {
      padding:18px !important;
    }

    table.sonata-ba-list th {
      background:transparent;
    }

    .table-striped>tbody>tr:nth-of-type(odd) {
        background-color: white;

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }
    }

    .table-bordered > thead > tr > th,
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > td {
      border:1px solid #f3f1f1;
    }

    .table-striped tbody tr.sonata-ba-list-row-selected td,
    .table-striped tbody tr.sonata-ba-list-row-selected th {
      background-color:#20ade31c;
    }

    .sonata-ba-list-field-header {
      th {
        white-space:nowrap;
      }
    }

    th.sonata-ba-list-field-header-order-asc a:hover:after,
    th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:after,
    th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:hover:after {
        content: "\e874";
        font-family: Linearicons-Free;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        display: block;
        border-top:0;
        position: absolute;
        top: 2px;
        right: -28px;
        margin-top: -1px;
        color:white !important;
    }

    th.sonata-ba-list-field-header-order-desc a:hover:after,
    th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:after,
    th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:hover:after {
        content: "\e873";
        font-family: Linearicons-Free;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        display: block;
        border:0;
        position: absolute;
        top: 4px;
        right: -24px;
        margin-top: -5px;
        color:white !important;
    }

    table.table-bordered {
      tr.sonata-ba-list-field-header {
        border:1px solid rgb(222, 226, 230);
        border-bottom:0;

        th {
          border:0;
          padding: 11px;
          font-weight:500;

          a {
            //color:$bleu;
            color:$txt-chart;
          }

          &.sonata-ba-list-field-order-active {
            a {
              font-weight:500;
            }
          }

          &.sonata-ba-list-field-header-text {
            color:$txt-chart;
            font-weight:500;
            font-size:14px;
            font-family:'Montserrat', sans-serif;
          }
        }
      }

      tr {
        td {
          color:$fonce;
          font-weight:400;
        }
      }
    }

    .info-box {
      box-shadow:none;

      .bg-aqua {
        background-color:$gris !important;
        opacity:.22;
      }

      .info-box-icon .fa {
        font-family: Linearicons-Free;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;

        &:before {
          content:"\e880";
        }
      }

      .progress {
        background-color:$gris !important;
        opacity:.22;
        height:1px;
      }

      a.sonata-action-element {
        display:inline-block;
        border: 0;
        background: $bleu !important;
        color: white !important;
        padding: 8px 12px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        transition: 0.4s all ease;
        box-shadow: 0 !important;
        outline: none !important;
        margin-top:8px;

        .fa {
          font-family: Linearicons-Free;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;

          &.fa-plus-circle {
            &:before {
              content:"\e881";
            }
          }
        }

        &:hover {
          opacity:.75;
        }
      }
    }
  }
}

.alert {
  border:0 !important;
  border-radius:4px !important;

  &.alert-dismissible {
    input[type=checkbox], .icheckbox_square-blue, .read-more-trigger  {
      display:none;
    }
  }
}

.pagination > li > a {
  background:white;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background:$bleu;
  border-color:$bleu;
}

.card-header {
  background:$bleu;
  color:white !important;
  padding:4px 15px;
  display:inline-block;
  width:100%;
  border-top-right-radius: 4px;
  border-top-left-radius:4px;
  font-size:18px;
  font-weight:400;
  transition:0.4s all ease;

  &.collapsed {
    border-radius:4px;
  }
}

.sonata-ba-field-error-messages {
  color:$rouge !important;
}

.sonata-ba-field {
  div.sonata-ba-field-error input {
    border:1px solid $rouge !important;
  }

  &.sonata-ba-field-error {
    input {
      border-color:$rouge !important;
    }
  }

  &.sonata-ba-field-error {
    input[type=file] {
      border-color:$rouge !important;
    }
  }
}

.form-group.has-error label {
  color:$rouge !important;
}

.form-group.inline-form {
  display:inline-block;
}

.form-inline-container {
  display:flex;
  justify-content:space-between;
}

.form-inline-container > div:first-child {
  margin-right:4px;
}

.form-inline-container > div:last-child {
  margin-left:4px;
}

.bg-red,
.callout.callout-danger,
.alert-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
  background-color: $rouge !important;
  border-radius:0;
  border:0;
}

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size:11px;

  &.bg-red {
    background-color: $rouge !important;
  }

  &.bg-blue {
    background-color: $bleu !important;
  }

  &.bg-orange {
    background-color: $orange !important;
  }

  &.bg-dark {
    background-color: $dark !important;
  }

  &.bg-green {
    background-color: $vert !important;
  }

  &.bg-purple {
    background-color:$purple !important;
  }

  &.lb-padding {
    margin-bottom: 2px;
    display: inline-block;
  }
}

.content-header {
  display:none;
}

.sonata-filters-box {
  .box {
    //margin-bottom:0;
  }
}

.card-title {
  line-height: 22px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight:500;
  color:#455a64;
  padding-left:10px;
  padding-top:10px;
}

.card-subtitle {
  font-weight: 300;
  margin-bottom: 15px;
  color: $txt-clair;
  line-height: 16px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  padding-left:10px;
}

.form-group label {
  margin-bottom: 12px !important;
  font-weight:400;
  color:$txt-chart;
  font-family: "Montserrat", sans-serif;
  font-size:14px !important;
  text-align: left !important;
  width: 100%;
}

.sonata-filter-form {
  padding:11px;

  .form-group {
    margin-bottom:4px;
  }
}

.select2-container .select2-choice,
.select2-container .select2-choices,
.select2-container .select2-choices .select2-search-field input {
    display: block;
    width: 100%;
    padding: .5rem .8rem;
    height:40px !important;
    color: $gris-select !important;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    box-shadow:none !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size:14px !important;
    font-family: "Montserrat", sans-serif;
}

.select2-container .select2-choices {
  height:auto !important;
}

.select2-container .select2-choice .select2-arrow b, .select2-container .select2-choice div b {
    background-position: -4px 7px;
}

.select2-drop {
  box-shadow:none !important;
}

.select2-results {
  margin:0;
  padding:0;

  .select2-result-label {
    color:$gris-select;
    opacity:0.9;
    font-weight:400;
    padding-left:18px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .select2-highlighted {
    background:#20ade31c;
  }
}

.select2-search input {
  border-color: #e0e0e0;
  border-radius: 0 !important;
  color: #555;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  box-shadow:none !important;
  background: url(select2.png) no-repeat 100% -22px
}

.select2-drop-auto-width, .select2-drop.select2-drop-above.select2-drop-active {
  border-color: #dee2e6;
}

.box-body {
  .form-group {
    input {
      height:40px;
      border-radius: 4px !important;

      &:focus {
        color: $gris-select !important;
        background-color: #fff !important;
        border-color: #80bdff !important;
        outline: 0;
        box-shadow:0 0 0 4px rgba(0,123,255,.25) !important;
      }
    }
  }
}

.filter-row {
  display:flex !important;
  justify-content:flex-start !important;
  flex-wrap:wrap !important;
  margin-left: -15px !important;
  margin-right: -120px !important;

  @media screen and (max-width: 1200px) {
    margin-right: -75px !important;
  }

  @media screen and (max-width: 768px) {
    margin-right: -45px !important;
  }

  input[type=text].form-control {
    height:40px;
    border-radius: 4px !important;

    &:focus {
      color: $gris-select !important;
      background-color: #fff !important;
      border-color: #80bdff !important;
      outline: 0;
      box-shadow:0 0 0 4px rgba(0,123,255,.25) !important;
    }
  }

  .btn-primary {
    border:0;
    background: $vert !important;
    color: white !important;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    transition: 0.4s all ease;
    box-shadow:0 !important;
    outline:none !important;
  }

  .btn-default {
    border:0;
    background: $dark !important;
    color: white !important;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    transition: 0.4s all ease;
    box-shadow:0 !important;
    outline:none !important;
  }

  .btn-default, .btn-primary {
    &:hover {
      opacity:0.75 !important;
    }
  }
}

.filter-row-requeteur {
  overflow:hidden;
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.btn-default {
  outline:none !important;
  box-shadow: none !important;
}

.btn-outline-success {
  color: $vert !important;
  background-color: transparent;
  border-color: $vert;
  transition: 0.4s all ease;
  box-shadow: none !important;
  outline:none !important;

  &:hover {
    background-color: $vert;
    color:white !important;
  }
}

.btn-outline-danger {
  color: $rouge !important;
  background-color: transparent;
  border-color: $rouge;
  transition: 0.4s all ease;
  box-shadow: none !important;
  outline:none !important;

  &:hover {
    background-color: $rouge;
    color:white !important;
  }
}

.btn-outline-warning {
  color: $orange !important;
  background-color: transparent;
  border-color: $orange;
  transition: 0.4s all ease;
  box-shadow: none !important;
  outline:none !important;

  &:hover {
    background-color: $orange;
    color:white !important;
  }
}

.btn-outline-purple {
  color:$purple !important;
  background-color: transparent;
  border-color:$purple;
  background:transparent;
  transition: 0.4s all ease;
  box-shadow: none !important;
  outline:none !important;

  &:hover {
    background:$purple;
    color:white !important;
  }
}

.bdco_ligne_container, #avenants-container {
  .btn-outline-success, .btn-outline-danger, .btn-outline-warning, .btn-outline-purple {
    display:inline-block;
    height:36px;
  }
}

.sonata-link-identifier {
  font-weight:300 !important;
  color:$fonce;

  &.projet-actifÂ {
    color:$bleu;
  }
}

.sonata-link-identifier-cog {
  color:$fonce;

  &.projet-actifÂ {
    color:$bleu;
  }
}

.content {
  padding-left:24px;
  padding-right:24px
}

.highlight, .highlighted-txt {
  background-color:rgba(255, 204, 89, 0.59);
}

.form-actions.stuck {
  background:white;
  box-shadow:none;
  margin-left:-40px;
  border-radius:0;
  border-top:1px solid $border;
  border-left:1px solid $border;
}

.well {
  background:white;
  border:0;
  border-radius:0;
}

.btn-success {
  background-color:$vert !important;
  border-radius:4px;
  border: 0;
  color: white !important;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.4s all ease;
  box-shadow: 0 !important;
  outline: none !important;

  &:hover {
    opacity:0.75;
  }

  &[name=btn_update_and_list] {
    background-color:$dark !important;
  }
}

.btn-purple {
  background-color:$purple !important;
  border-radius:4px;
  border: 0;
  color: white !important;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.4s all ease;
  box-shadow: 0 !important;
  outline: none !important;

  &:hover {
    opacity:0.75;
  }
}

.btn-dark {
  background-color: $dark !important;
  border-radius: 4px;
  border: 0;
  color: white !important;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.4s all ease;
  box-shadow: 0 !important;
  outline: none !important;

  &:hover {
    opacity:0.75 !important;
  }
}

.box-title {
  line-height: 22px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #455a64;
  padding-bottom: 10px;
  padding-top: 10px;
}

.nav-tabs-custom {
  box-shadow:none;
  margin:-15px;

  & > .nav-tabs-manual {
    border-bottom: 1px solid #ddd;
    border-bottom-color:#dee2e6;
    margin-bottom:8px;
    display:flex;
    flex-wrap:wrap;

    & > li {
      border-top:0 !important;
      padding: 5px;
      transition:0.4s color ease;
      margin-bottom: -2px;
      float:left;

      a {
        transition:0.4s color ease;
        color:$txt-chart;
        background:white;
        padding: 11px 16px;

        &:hover {
          color:$bleu;
        }
      }
    }

    & > li.active {
      border-top:0;
      background:$bleu;
      margin-bottom:0;

      & > a {
        border-right-color:transparent;
        border-left-color:transparent;
        color:white !important;
        background:$bleu;
      }
    }
  }
}

.required:after {
  color:$rouge;
  content:'obligatoire';
  font-size:8px;
  font-weight:500;
  vertical-align: top;
}

.select2-search input {
  background-image: url(select2-sm.png) !important;
}

.select2-container-multi {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
      background-image: url('select2x2.png') !important;
      background-repeat: no-repeat !important;
      background-size: 60px 40px !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {
    .select2-container-multi {
      .select2-search input,
      .select2-search-choice-close,
      .select2-container .select2-choice abbr,
      .select2-container .select2-choice .select2-arrow b {
          background-image: url('select2x2.png') !important;
          background-repeat: no-repeat !important;
          background-size: 60px 40px !important;
      }
    }

    .select2-search input {
        background-position: 100% -21px !important;
    }
}

.sonata-ba-form-actions {
  width:100%;

  @media screen and (min-width: 768px) {
    &:not(.main-actions-fixed-container) {
      background: white;
      box-shadow: none;
      border-radius: 0;
      border-top: 1px solid $border;
      position: fixed;
      bottom: 0;
      z-index:5;
      margin-bottom:0 !important;
      margin-left:-40px;
      border-left:1px solid $border;
    }
  }
}

.sonata-ba-form-actions.main-actions-fixed-container {
  left: auto !important;
  margin-left: -1px;
  top: 82px;
  width:auto !important;
  height: 74px;
  right:0 !important;
  position: fixed;
  border: 1px solid rgba(120, 130, 140, 0.13);
  box-shadow:none;
  z-index:1000;

  // @media screen and (min-width: 768px) {
  //   background: white;
  //   box-shadow: none;
  //   border-radius: 0;
  //   border-top: 1px solid $border;
  //   position: fixed;
  //   bottom: 0;
  //   z-index:5;
  //   margin-bottom:0 !important;
  //   margin-left:-40px;
  //   border-left:1px solid $border;
  // }
}

.animated.delay-04s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.4s;
}

.modal-body {
  .sonata-ba-form-actions {
    margin-left:-15px;
  }
}

.select2-container .select2-choices .select2-search-field input {
  border:0px !important;
  height:42px !important
}

.select2-container-multi .select2-choices .select2-search-choice {
  margin-top:9px !important;
  border:0;
  background:$bleu;
  color:white;
}

.blue-link {
  color:$bleu !important;

  &:hover {
    text-decoration:underline;
  }
}

.select2-container .select2-choice,
.select2-container .select2-choices,
.select2-container .select2-choices .select2-search-field input {
  box-shadow:none !important;
}

.select2-container .select2-choices {
  padding-top:0;
}

.alert-success {
  background-color:$vert !important;
}

.cerebro-loader-manuel,
.cerebro-loader {
  position:fixed;
  right:48px;
  border-radius:24px;
  bottom:108px;
  background:$dark;
  color:white;
  padding:16px 24px;
  display:none;
  z-index:9999;

  span {
    display:inline-block;
    margin-right:8px;
    font-size:18px;
    vertical-align: middle;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.cerebro-error-loader {
  position:fixed;
  display:none;
  right:48px;
  border-radius:24px;
  bottom:108px;
  background:$dark;
  color:white;
  padding:16px 24px;
  z-index:9999;

  span {
    display:inline-block;
    margin-right:8px;
    font-size:18px;
    vertical-align: middle;
  }
}

.table-cerebro-list {
  margin-top:6px;
  th {
    font-weight: 500;
    color:$txt-chart;
    font-size:14px;
    padding: .85em;
    padding-bottom:12px;
  }

  & > thead > tr > th {
    border-bottom-width:1px;
    padding-bottom:12px;
  }

  & > tbody > tr > td {
    font-weight:300;
    color:#4c575d;
    padding-bottom:0px !important;
    padding-top:12px !important;
  }
}

.btn-warning.btn-sm.sonata-ba-action {
  background:$orange;
  border:0 !important;
  border-radius:4px;
  color:white !important;
  transition:0.4s all ease;

  &:hover {
    opacity:0.75;
  }
}

.modal-title {
  line-height: 22px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight:500;
  color:#455a64;
  padding-left:10px;
  padding-top:10px;
}

.modal-body, .sonata-ba-form-actions {
  .btn.btn-danger {
    border-radius: 4px;
    border: 0;
    color: white !important;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    transition: 0.4s all ease;
    box-shadow: 0 !important;
    outline: none !important;

    &:hover {
      opacity:0.75;
    }
  }
}

.modal-content {
  overflow:hidden !important;
}

.box.box-danger {
    border-top-color: transparent;
}

.sonata-ba-field-list-natural {
  .field-container {
    display:flex;

    .field-short-description {
      min-width: 200px;
      height: 34px;
      margin:0 !important;
      border: 1px solid $bleu;
      border-radius: 4px 0px 0px 4px;
      overflow:hidden;
    }

    .btn {
      display:inline-block;
      height:34px !important;
      color:white !important;
      background:$bleu !important;
      border:0;
      font-size: 12px !important;
      line-height:24px;
      padding:5px !important;
      transition:0.4s all ease;

      &:hover {
        opacity:0.75;
      }
    }
  }
}

.box-body .form-group input[readonly],
.box-body .form-control[disabled],
.box-body form-control[readonly],
.box-body fieldset[disabled] .form-control,
.select2-container.select2-container-disabled .select2-choice, .select2-container.select2-container-disabled .select2-choices{
    background-color: #eee !important;
    opacity: 1;
    user-select:none;

    &:focus {
      box-shadow:none !important;
      background-color: #eee !important;
      border-color: #ccc !important;
      outline: 0;
      box-shadow: none !important;
    }
}

.radio {
  margin-top:0;
  margin-bottom:0;
  .required {
    &:after {
      display:none;
    }
  }
}

.inner-field-short-description {
    margin-top: 2px;
    font-size:13px !important;
    display: inline-block;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
  padding-left:10px !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active,
.select2-more-results.select2-active,
.select2-search input.select2-active {
  padding: 4px 12px !important;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  border-color:$bleu !important;
  background:$bleu !important;
}

.flatpickr-day{
  font-weight:300 !important;

  &.today {
    border-color:$bleu !important;
  }
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background:$bleu !important;
  opacity:.8;
}

.flatpickr-current-month span.cur-month {
  font-weight:500 !important;
}

span.flatpickr-weekday {
  font-weight: 400 !important;
}

.box-body .form-group .flatpickr-input[readonly] {
  background-color:white !important;

  &:focus {
    background-color:white !important;
  }
}

.analytique-factures-emises {
  margin-top:24px !important;

  .table {
    border:0 !important;
    border-right:2px solid $bleu !important;
    border-left:2px solid $bleu !important;

    thead, tfoot {
      tr {
        th {
          border:0 !important;
          background:$bleu;
          color:white;
          padding:12px;
          font-size:14px;
          font-weight: 500;
        }
      }
    }

    tbody {
      td {
        border-top: 1px solid #f3f1f1;
        padding:12px;
        vertical-align: top;
        color:$txt-chart;
        font-weight:300;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 64px !important;
  }


  .sidebar-mini.sidebar-collapse .content-wrapper {
    width:calc(100% - 64px) !important;
  }
}

.table-analytique-full, .list-requeteur-comptable {
  border:0;
  width:100%;

  th, td {
    border:0 !important;
  }

  tbody, tfoot {
    tr {
      td, th {
        white-space: nowrap;
        padding-top:12px !important;
        padding-bottom:12px !important;
      }
    }
  }

  .active-cell-analytique {
    background:rgba(229, 247, 255, 0.6);
  }

  tr.sonata-ba-list-field-header {
    border:0 !important;
  }

  thead, tfoot {
    border:1px solid $bleu;

    th {
      background:$bleu !important;
      color:white !important;
      font-weight:500!important;

      a {
        color:white !important;
      }
    }
  }

  tfoot {
    th {
      font-weight:400;
      color:$txt-chart;
    }
  }
}

.table-analytique {
  border:0 !important;
  border-right:2px solid $dark !important;
  border-left:2px solid $dark !important;

  thead, tfoot {
    tr {
      th {
        border:0 !important;
        background:$dark;
        color:white !important;
        padding:14px 12px;
        padding-bottom:16px !important;
        font-size:13px !important;
        font-weight: 500 !important;
      }
    }
  }

  tfoot {
    tr {
      th {
        border:1px solid white !important;
        border-bottom:0px solid $dark!important;
      }
    }
  }

  tbody {
    tr {
      td {
        //transition:0.3s background ease;
      }

      &:hover {
        td {
          background:rgba(0, 0, 0, 0.02);
        }
      }
    }

    td {
      border-top: 1px solid #f3f1f1;
      padding:12px;
      vertical-align: top;
      color:$txt-chart;
      font-weight:300;
    }
  }

  .control-group {
    padding-bottom:6px !important;

    input {
      height:30px !important;
      max-width: 120px;
    }
  }

  .select2-container .select2-choice,
  .select2-container .select2-choices,
  .select2-container .select2-choices .select2-search-field input {
    height:30px !important;
  }
}

.data-red {
  color: #ff5c6c !important;
  font-weight:400;
}

.data-green {
  color: $vert !important;
  font-weight:400;
}

.data-orange {
  color: $orange !important;
  font-weight:400;
}

.data-blue {
  color: $bleu !important;
  font-weight:400;
}

div[id$=_coutsProjet] {
  & > label {
    display:none;
  }
}

.analytique-bilan {
  padding-right:25px;

  .box {
    .box-header {
      background: $dark;
      border: 0 !important;

      .box-title {
        color: white;
        padding:0;
        padding-top:8px;
        padding-bottom:8px;
        font-size: 14px !important;
        font-weight: 500;
      }
    }

    border-left: 2px solid $dark;
    border-right: 2px solid $dark;

    .box-body {
      padding:0;
    }
  }

  .form-group {
    display:flex;
    border-bottom:1px solid $border;
    padding:12px;
    padding-top:4px;
    padding-bottom:4px;
    vertical-align: middle;
    align-items: center;
    margin-bottom:0;

    &[id$=__resultatAnalytique],
    &[id$=__margeAnalytique] {
      background:$dark;

      label {
        color:white !important;
        font-weight:500;
      }

      .input-group-addon {
        border:0;
        background:transparent !important;
        color:white;
        padding:0;
        font-weight:500 !important;
      }

      input {
        font-weight:500 !important;
      }
    }

    &:last-child {
      border-bottom: 2px solid $dark;
    }

    .control-label {
      margin-bottom:0 !important;
    }

    input {
      border:0;
      background:transparent !important;
      padding:0;
      height:30 !important;

      &[readonly], &[disabled] {
        background-color:transparent !important;
        cursor:text !important;
      }
    }

    .input-group-addon {
      border:0;
      background:transparent !important;
      padding:0;
    }
  }

  @media screen and (max-width:993px) {
    padding-left: 25px;
  }
}

.analytique-total-prod, .proces-verbaux-table {
  .table {
    border:0 !important;
    border-right:2px solid $dark !important;
    border-left:2px solid $dark !important;

    thead, tfoot {
      tr {
        th {
          border:0 !important;
          background:$dark;
          color:white !important;
          padding:14px 12px;
          padding-bottom:16px !important;
          font-size:14px !important;
          font-weight: 500 !important;
        }
      }
    }

    tfoot {
      tr {
        th {
          border:0px solid white !important;
          border-bottom:0px solid $dark!important;
        }
      }
    }

    tbody {
      tr {
        td {
          //transition:0.3s background ease;
        }

        &:hover {
          td {
            background:rgba(0, 0, 0, 0.02);
          }
        }
      }

      td {
        border-top: 1px solid #f3f1f1;
        padding:12px;
        vertical-align: top;
        color:$txt-chart;
        font-weight:300;
      }
    }

    .control-group {
      padding-bottom:6px !important;

      input {
        height:30px !important;
        max-width: 120px;
      }
    }
  }

  padding-left:0px;

  @media screen and (max-width:993px) {
    padding-right:10px;
  }
}

.proces-verbaux-table {
  .table {
    border:0 !important;
    border-right:2px solid $bleu !important;
    border-left:2px solid $bleu !important;
    border-bottom:2px solid $bleu !important;

    thead, tfoot {
      tr {
        th {
          border:0 !important;
          background:$bleu;
          color:white;
          padding:12px;
          font-size:14px;
          font-weight: 500;
        }
      }
    }
  }
}

.tab-suisse {
  display:none;
}

.table-responsive {
  overflow-y: hidden;
  border:0 !important;
}

.table-production {
  border:0 !important;
  border-collapse: collapse !important;

  thead {
    tr {
      border:1px solid #dee2e6 !important;
    }

    th {
      border: 0px !important;
      font-weight: 500 !important;
      color:$txt-chart;
      padding:12px !important;
      white-space: nowrap;
      outline:none !important;
    }
  }

  tbody {
    tr {
      td {
        border:1px solid #f3f1f1 !important;
        color: $txt-chart;
        font-weight:400;
        font-size:14px;
        padding:14px 12px;
      }
    }
  }

  &.table-analytique-suivi {
    table.table {
      thead {
        th {
          border: 0px !important;
          font-weight: 500 !important;
          color:$txt-chart;
          padding:12px !important;
          white-space: nowrap;
          outline:none !important;
        }
      }

      tfoot {
        tr {
          border:1px solid #dee2e6 !important;
        }
      }
    }
  }
}

.etats-prod-list {

  .box-body {
    overflow-x:auto;
    overflow-y: hidden;
  }

 ul {
   min-width:1040px;
   list-style-type: none;
   display:flex;
   align-items:flex-start;
   justify-content:flex-start;

   li {
     background:$bg-clair;
     height:80px;
     display:inline-block;
     margin: 0 3px;
     padding: 10px 0px 10px 46px;
     min-width: 180px;
     width: 100%;
     display:flex;
     align-items:center;
     font-size:12px !important;
     position:relative;

     .radio {
       width:100%;
     }

     .radio label {
      padding-left:0 !important;
      margin-bottom:0 !important;
     }

     &:first-child {
       margin-left:0;
     }

     &:last-child {
       margin-right:0;

       &:after, &:before {
         display:none;
       }
     }

     &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: -20px;
      width: 0;
      height: 0;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 20px solid $bg-clair;
      z-index: 2;
     }

     &:before {
      content: " ";
      position: absolute;
      top: 0;
      right: -26px;
      width: 0;
      height: 0;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 20px solid white;
      z-index: 2;
     }

     &.selected-step {
       background:#2489c5;

       label {
         color:white !important;
       }

       &:after {
         border-left: 20px solid #2489c5;
       }
     }
   }
 }
}

.suivi-etapes-widget {
  margin-top:32px;
  margin-bottom:-20px !important;

  .box-header {
    padding-left:0px;
    padding-bottom:16px;
  }

  .table {
    background:$bg-clair;

    tr {
      th, td {
        border:4px solid white !important;
        font-weight:400;
        color:$txt-chart;
      }
    }
  }
}

.modal-sm, .modal-md, .modal-lg {
  .btn {
    background-color: $vert !important;
    border-radius: 4px;
    border: 0;
    color: white !important;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    transition: 0.4s all ease;
    box-shadow: 0 !important;
    outline: none !important;

    &:hover {
      opacity:0.75 !important;
    }
  }

  .form-actions, .modal-footer {
    .btn {
      &.btn-default {
        background-color: $dark !important;
      }

      &.btn-danger {
        background-color: $rouge !important;
      }

      &.btn-purple {
        background-color: $purple !important;
      }
    }
  }
}

.sonata-ba-delete {
  .btn {
    &.btn-default {
      background-color: $dark !important;
    }

    &.btn-danger {
      background-color: $rouge !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 0px !important;
  }
}

.modal {
  .form-group {
    input[type=file] {
      background:$bg-clair;
      border: 2px dashed #dcdcdc;
      height:100px;
      box-shadow:none !important;
    }
  }
}

.form-group {
  input[type=file] {
    background:$bg-clair;
    border: 2px dashed #dcdcdc;
    padding:12px 20px;
    padding-bottom:36px;
    box-shadow:none !important;
  }
}

.maquettes-form-container {
  background:$bg-clair;
  padding:12px 20px;
  border:1px solid $border;
  border-bottom:0;

  .row {
    margin-top:8px;
  }

  .col {
    padding-left:5px;
    padding-right:5px;

    @media screen and (max-width: 991px) {
      padding-left:15px;
      padding-right:15px;
    }
  }

  button {
    margin-top:0;

    @media screen and (max-width: 991px) {
      margin-top:15px;
      width:100%;
      display:inline-block;
    }
  }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.2);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.2);
}

#table-suivi-bpp_filter, #table-suivi-webmas_filter, #table-suivi-cdp_filter, #phone-contacts_filter, #table-suivi-anal_filter {
  margin-top:0;
  width:100%;

  label {
    display:flex;
    width:100%;
    align-items:center;
    font-weight:500;
    margin-bottom:24px;
    color:$txt-chart;
    justify-content: space-between;

    input[type=search] {
      max-width:350px;
    }
  }

  input[type=search] {
    height:40px;
    border-radius: 4px !important;
    font-weight:300;


    &:focus {
      color: $gris-select !important;
      background-color: #fff !important;
      border-color: #80bdff !important;
      outline: 0;
      box-shadow:0 0 0 4px rgba(0,123,255,.25) !important;
    }
  }
}

#phone-contacts_filter {
  label {
    margin-bottom:0 !important;
    display:flex;
    justify-content:center;
    margin-top:12px;
  }
}

.maquettes-tree-container {
  .action-btn-container, .file-action-btn-container {
    display:inline-block;
    position:absolute;
    margin-top:6px;
    right:50px;
    z-index:2;
  }

  .file-action-btn-container {
    right:0;

    .btn {
      transition:0.4s all ease;

      &.btn-default {
        color:$purple !important;
        border-color:$purple;
        background:transparent;

        &:hover {
          background:$purple;
          color:white !important;
        }
      }
    }
  }

  .pre-info-dossier {
    display:inline-block;
    position:absolute;
    top:-2px;
    left:6px;
    z-index:2;
    width:30px;
    text-align:center;
    color:$gris;
    font-size:12px;
  }

  .auteur-small-txt {
    font-size:12px;
    color:$gris;
    opacity:.7;
    margin-left:12px;
  }

  small.label {
    margin-left:10px;
    background:$purple;
  }

  .tree {
    border:1px solid $border;
    border-top:0;
    margin: 0;
    padding: 0;
    padding-top:10px;
    list-style: none;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-left: 1em;
      position: relative;
      ul {
        margin-left: 0.5em;
      }
      &:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid;
        border-color:#dedede;
      }
    }
    li {
      margin: 0;
      padding: 0 3em;
      line-height: 3.2em;
      color: $txt-chart;
      font-weight: 400;
      position: relative;
    }

    & > li {
      border-top:1px solid rgba(0, 0, 0, 0.05);

      &:hover {
        background:rgba(0, 0, 0, 0.01);
      }
    }

    ul li {
      &:before {
        content: "";
        display: block;
        width: 28px;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 2em;
        left: 0;
        border-color:#dedede;
      }
      &:last-child:before {
        background: #fff;
        height: auto;
        top: 2em;
        bottom: 0;
      }

      &.no-before {
        padding-left:1em;
        font-size:12px;
        color:$gris;
        opacity:.7;
        &:before {
          border-color:transparent;
        }
      }
    }
  }

  .indicator {
    margin-right: 10px;
    font-size:20px;
    color:#ec9862;
    cursor:pointer;
  }

  .glyphicon-picture {
    color:#d292e0;
  }

  .tree li {
    a {
      text-decoration: none;
      color: $txt-chart;
      font-weight: 400;
    }
    button {
      text-decoration: none;
      color: #369;
      border: none;
      background: transparent;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      outline: 0;
      &:active, &:focus {
        text-decoration: none;
        color: #369;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;
      }
    }
  }
}

.filter-type-sonata_type_date_range_picker {
  & > div {
    & > div {
      display:flex;
      align-items:center;
      margin-bottom: 5px;

      label {
        width:20%;
      }
    }
  }
}

.separator-bloc-filters {
  width:100%;
  border-top:1px solid rgba(0,0,0,.1);
}

th.sorting {
  outline: none !important;
}

.row-eq-height {
  display:flex;
  flex-wrap:wrap;
}

div[aria-labelledby="myModalLabel"] {
  .modal-body {
    height:calc(90% - 64px) !important;
  }
}

.list-requeteur-comptable, .table-analytique-full-cdp {
  font-size:11px !important;
  font-family:'Montserrat';

  thead {
    th {
      font-size:11px !important;
      font-family:'Montserrat';
    }
  }
}

.route-analytique_list, .route-commercial_list, .route-technique_list {
  section.content {
    .box-primary {
      margin-bottom:20px !important;
    }

    .list-requeteur-container {
      overflow:hidden !important;

      &:not(.no-result) {
        min-height:400px;
      }
    }

    .box-footer {
      border-top:0 !important;
      padding-top:15px !important;

      hr {
        display:none !important;
      }
    }
  }


  .pull-right {
    margin-right:10px;
    font-weight: 300;

    .control-label {
      font-weight:500;
    }

    .btn {
      background:$bleu;
      border-radius:4px !important;
      border:0;
      height:40px;
      font-weight: 400;
      font-size: 16px;
      color:white;
      transition:all 0.4s ease;

      &:hover {
        opacity:.75;
      }

      .fa-share-square-o {
        &:before {
          content:"\e867";
          font-family: Linearicons-Free;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
        }
      }
    }
  }
}

.table-suivi-anal {
  tbody {
    & > tr:first-child {
      border-top:0 !important;
    }
  }
}

.col-profil-left {
  margin:-15px;
  margin-right:15px;

  @media screen and (max-width:993px) {
    margin:-15px;
  }

  small.text-muted {
    color:$txt-clair !important;
  }
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #455a64;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-top:0;
}

.hide-title {
  .box-title {
    visibility:hidden;
  }
}

.hide-header {
  .box-header {
    display:none;
  }
}

.box {
  &.profil-image-box {
    min-height:400px;
    border-top:0;
    background:url('bg-profil.jpg') no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;

    .rollover-container {
      display:flex;
      justify-content:center;
      align-items:center;
      position:absolute;
      background:rgba(13, 14, 41, 0.8);
      top:0;
      left:0;
      right:0;
      bottom:0;

      .profil-pic {
        width:128px;
        height:128px;
        border-radius:50%;
        object-fit: cover;
        object-position:center;
        display:inline-block;
        margin-bottom:0;
        border:3px solid white;
      }

      .profil-pic-no-exist {
        width:128px;
        height:128px;
        border-radius:50%;
        object-fit: cover;
        object-position:center;
        display:inline-block;
        margin-bottom:0;
        border:3px solid white;
        background:$gris;
        text-align:center;

        &:after {
          content:"Pas d'image";
          font-weight:300;
          font-size:12px;
          padding-top:calc(50% - 8px);
          color:white;
          display:inline-block;
        }
      }

      .card-title {
        margin-top:0;
        margin-bottom:0;
      }

      .card-subtitle {
        margin-bottom:0;
        margin-top:-14px;
      }

      p.text-white {
        width:70%;
        display:inline-block;
        color:white;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  &.box-user-list {
    height:140px;

    h3 {
      &.box-title {
        color: #455a64;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        line-height: 30px;
        font-size: 18px;
        margin:0;
        padding:0;
      }
    }

    small {
      color:$txt-chart;
      font-size: 80%;
      font-weight: 400;
    }
  }

  .profil-pic {
    width:92px;
    height:92px;
    border-radius:50%;
    object-fit: cover;
    object-position:center;
    display:inline-block;
    margin-bottom:0;
    border:3px solid white;

    @media screen and (max-width:1460px)  {
      width:72px;
      height:72px;
    }
  }

  .profil-pic-no-exist {
    width:92px;
    height:92px;
    border-radius:50%;
    object-fit: cover;
    object-position:center;
    display:inline-block;
    margin-bottom:0;
    border:3px solid white;
    background:$gris;
    text-align:center;

    @media screen and (max-width:1460px)  {
      width:72px;
      height:72px;
    }

    &:after {
      content:"Image";
      font-weight:300;
      font-size:11px;
      padding-top:calc(50% - 8px);
      color:white;
      display:inline-block;
    }
  }

  p.tel-bureau {
    color:$txt-chart;
    font-weight: 300;
    font-size: 14px;
    margin-top:8px;
    display:inline-block;
  }
}

.admin-admin-pays,
.admin-admin-objectifs,
.admin-admin-statuts_societes,
.admin-admin-activite,
.admin-admin-administrateurs_t,
.admin-admin-serveur_ftp_t,
.admin-admin-hebergeur,
.admin-admin-suivi_commercial_t,
.admin-admin-article,
.admin-admin-famille_article,
.admin-admin-type_tache_projet
 {
  .sonata-ba-form-actions {
    left: 260px;
    margin-left: -1px;
    transition:0.4s all ease;
  }

  .sonata-ba-form-actions.stuck {
    left: 0px;
    margin-left: -1px;
  }

  &.sidebar-collapse {
    .sonata-ba-form-actions {
      left: 66px;
      margin-left: -1px;
    }
  }
}

ul.list-style-none {
  list-style-type: none;
  padding:0;

  li.divider {
    margin: 10px 0;
    height: 1px;
    background: rgba(120, 130, 140, 0.13);
  }

  li.box-label {
    font-weight:500;
    color: $txt-chart;
    padding: 8px 0px;
    display: block;
    text-decoration: none;
    transition:0.4s all ease;

    span {
      font-size:18px;
      float:right;
    }
  }

  li {
    padding:0;
    a {
      color: $txt-chart;
      padding: 8px 0px;
      display: block;
      text-decoration: none;
      transition:0.4s all ease;

      span {
        font-size:16px;
        float:right;
      }

      &:hover {
        color:$bleu;

        span {
          color:$bleu;
        }
      }
    }
  }
}

.cog-file-field {
  ul {
    padding:0;
    margin:0;
    list-style-type: none;
    li {
      padding:0;
      margin:0;
      color:$rouge;
      margin-top:8px;
    }
  }
}

.ico-abo, .ico-dashboard {
  font-size:48px;
}

.ico-dashboard {
  margin-top:8px;
  display:inline-block;
  font-size:32px;
}

.chiffre-abo, .chiffre-dashboard {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color:#455a64;
  line-height: 36px;
  font-size: 28px;

  .lnr-star {
    color:$orange;
  }
}

.chiffre-dashboard {
  font-size:24px;
  line-height: 30px;
}

.body-abo-suivi, .dashboard-box {
  padding-top:32px;
  padding-bottom:32px;

  small {
    color:$txt-clair;
    font-size:15px;
  }
}

.dashboard-box {
  padding:32px;

  small {
    font-size:14px;
  }
}

.notification-menu {
  .notify {
    position: relative;
    top: -32px;
    right: 10px;

    .heartbit {
      position: absolute;
      top: -20px;
      right: -4px;
      height: 25px;
      width: 25px;
      z-index: 10;
      border: 5px solid #ff5c6c;
      border-radius: 70px;
      animation: heartbit 1s ease-out;
      animation-iteration-count: infinite;
    }

    .point {
      width: 6px;
      height: 6px;
      border-radius: 30px;
      background-color: #ff5c6c;
      position: absolute;
      right: 6px;
      top: -10px;
    }
  }
}

.navbar-nav > .notification-menu > .dropdown-menu > .user-body {
  border-top:0 !important;
}

.navbar-nav > .user-menu {
  .profil-pic-no-exist {
    width:30px;
    height:30px;
    border-radius:50%;
    object-fit: cover;
    object-position:center;
    display:inline-block;
    margin-bottom: -8px;
    background:$gris;
    text-align:center;
    display:inline-block;
    margin-top:-12px;
    margin-right:6px;

    &:after {
      content:"Image";
      font-weight:300;
      font-size:9px;
      padding-top:calc(50% - 10px);
      color:white;
      display:inline-block;
    }

    @media screen and (max-width:768px) {
      margin-bottom: -8px;
      margin-top:-16px;
    }
  }
}


.profil-pic-no-exist-square {
  background:$gris;
  height:82px;
  width:100%;
  max-width: 100%;
  border-radius: 6px !important;
  display:inline-block;
  text-align:center;


  &:after {
    content:"Pas d'image";
    font-weight:300;
    font-size:12px;
    padding-top:calc(50% - 8px);
    color:white;
    display:inline-block;
    text-align:center;
  }

  @media screen and (max-width:768px) {
    height:37vw;
  }
}

.navbar-nav > .user-menu > .dropdown-menu {

  & > li.user-header {
    &.notif-header {
      font-weight: 500;
      padding: 15px 20px;
      border-bottom: 1px solid rgba(120, 130, 140, 0.13);
      font-size:16px;
    }
  }

  & > li.notif-body {
    padding:10px !important;
    padding-left:14px !important;

    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }

    .btn-circleÂ {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      padding: 10px;
    }

    h5 {
      margin: 5px 0px 0;
      line-height: 18px;
      font-size: 16px;
      font-weight: 400;
      color:#455a64;
      font-family: 'Montserrat', sans-serif;
    }

    .mail-contnet {
      display: inline-block;
      width: 75%;
      padding-left: 10px;
    }

    .mail-desc {
      font-size: 12px;
      display: block;
      margin: 1px 0;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $txt-chart;
      white-space: nowrap;
      font-family: 'Montserrat', sans-serif;
    }

    .time {
      font-size: 12px;
      display: block;
      margin: 1px 0;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $txt-chart;
      white-space: nowrap;
    }
  }
}

.navbar-custom-menu > .navbar-nav > li {
  & > .dropdown-menu-notifs {
    width: 340px;

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.user-menu.notification-menu {

  .notification-svg {
    width:54px;
    height:54px;
  }
}

.table-anuary {
  .profil-pic, .profil-pic-no-exist {
    width:48px;
    height:48px;
  }

  .profil-pic-no-exist {
    &:after {
      content:"Image";
      font-weight:300;
      font-size:10px;
      padding-top:calc(50% - 8px);
      color:white;
      display:inline-block;
    }
  }
}

#phone-contacts_wrapper {
  .dataTables_length {
    display:none;
  }

  .dataTables_paginate {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .paginate_button {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #666 !important;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius:0 !important;
    box-shadow:none !important;
    font-family: 'Montserrat' !important;

    &.next, &.previous {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }

    &.next {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    &.previous {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    &:hover {
      color: #23527c !important;
      background-color: #eee;
      border-color: #ddd;
      background-image: none;
    }

    &.current {
      background: $bleu;
      border-color: $bleu;
      color:white !important;

      &:hover {
        background: $bleu !important;
        border-color: $bleu !important;
        color:white !important;
      }
    }
  }
}

.box-welcome {
  color:$txt-chart;
  padding-left:20px;
  padding-right:20px;

  a {
    color:#a2a7af;
    font-weight:400;
    font-size:13px;
    display:inline-block;
    width:100%;
    height:100%;
    transition:0.35s all ease;

    &:hover {
      //background: rgba(0, 0, 0, 0.02);
      color:#455a64;

      span {
        color:#455a64;
      }
    }
  }

  span {
    color:#bcc3d3;
    transition:0.35s all ease;
  }

  div.text-center {
    padding:0 !important;
  }
}

.box-welcome-container {
  .col-md-6 {
    @media screen and (max-width: 992px) {
      width:100%;
    }
  }
}

.box-ca-container {
  @media screen and (max-width: 992px) {
    .col-md-5, .col-md-7 {
      width:100%;
    }
  }

  .box-primary {
    height: calc(100% - 26px);
  }
}

.box-message-board {
  .box-body {
    height:100%;

    & > .row {
      height:100%;

      .chat-col {
        height:400px;
        overflow: hidden;
        transition:height 0.3s ease;
      }
    }
  }

  .chat-box {
    display:none;
    height: 100%;
    overflow: scroll;

    .chat-list {
      margin: 0px;
      padding: 0px;
      margin-top:15px;
      margin-left:15px;

      li {
        list-style: none;
        font-family: "Montserrat", sans-serif;
        margin: 0;
        overflow-x: hidden;
        color: $txt-chart;
        font-weight: 300;
        font-size: 14px;

        &.odd {
          .box {
            clear: both;
          }

          .chat-content {
            text-align:right;
            width:calc(100% - 90px);
          }
        }

        .chat-img {
          display: inline-block;
          width: 45px;
          height:45px;
          object-fit: cover;
          vertical-align: top;
          border-radius:50%;
          overflow:hidden;

          .profil-none {
            display: inline-block;
            width: 45px;
            height:45px;
            object-fit: cover;
            vertical-align: top;
            font-size:10px;
            padding-top:15px;
            color:white;
            background:#8d97ad;
            text-align:center;
          }

          img {
            display: inline-block;
            width: 45px;
            height:45px;
            object-fit: cover;
            vertical-align: top;
          }
        }

        .chat-content {
          width: calc(100% - 140px);
          display: inline-block;
          padding-left: 15px;

          h5 {
            color:$txt-clair;
            line-height: 18px;
            font-size: 14px;
            font-weight: 400 !important;
            font-family:'Montserrat';
          }

          .box {
            display: inline-block;
            margin-bottom: 10px;
            color: #263238;
            border-top:0;
            border-radius: 4px;
            padding: 10px;
            width:auto;

            &.bg-light-inverse {
              background-color: #f6f6f6;
            }

            &.bg-light-info {
              background-color: #cfecfe;
            }
          }
        }

        .chat-time {
          display: inline-block;
          text-align: right;
          width: 80px;
          font-size: 12px;
          color: #bcc3d3;
        }
      }
    }
  }

  .form-chat-container {
    margin:0;

    .col-md-12 {
      border-top:1px solid rgba(120, 130, 140, 0.13);
      margin-left:5px;
      width:calc(100% - 10px);
      padding:20px;
      padding-bottom:0;
    }

    .btn-circleÂ {
      border-radius: 100%;
      width: 48px;
      height: 48px;
      display: inline-block;
      border:0 !important;
    }

    textarea {
      color: $txt-chart;
      height: 48px;
      display: initial;
      width: 100%;
      line-height: 1.5;
      background-color: #fff;
      background-clip: padding-box;
      border: none !important;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      resize:none;

      &:focus {
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 3px rgba(0,123,255,.25);
      }
    }
  }
}

.sidebar-collapse {
  .notif-sidebar {
    margin-right:16px !important;
  }
}

input[type="text"].form-control-line {
  background-color: white !important;
  background: white !important;
  background-image: linear-gradient($bleu, $bleu), linear-gradient(#e9edf2, #e9edf2) !important;
  border: 0 none;
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  background-position: center bottom, center calc(100% - 1px) !important;
  background-repeat: no-repeat !important;
  background-size: 0 2px, 100% 1px !important;
  transition: background 0.4s ease !important;
  padding-left:2px;
  box-shadow:0 !important;
}

input[type="text"].form-control-line:focus {
  background-size: 100% 2px, 100% 1px !important;
  outline: 0 none;
  transition-duration: 0.3s;
}

.table-bdco-text {
  tbody td, tbody th, tfoot td, tfoot th {
    font-weight:400;
  }
}

.hidden-box {
  display:none;
}

.delete-td-container {
  .btn {
    transition:0.4s all ease;

    &:hover {
      opacity:0.8;
    }
  }
}

.input-group-flex {
  display:flex;
}

.input-group-flex .input-group-addon {
  display:flex;
  align-items:center;
}

ul.list-two-columns {
  width:100%;
}

ul.list-two-columns li {
  float:left;
  width:50%;
}

ul.list-two-columns li .checkbox {
  margin-bottom: -2px;
  padding-top: 1px;
}

small.created-by {
  display:inline-block;
  margin:8px 6px;
  color:$txt-chart;

  bÂ {
     font-weight:500;
     color:#455a64;
  }
}

.star-rating {
  color:$txt-chart;
  vertical-align: middle;
  display:inline-block;

  .fa {
    color:$orange;
    font-size:30px;
    cursor:pointer;
    vertical-align: middle;
    display:inline-block;

    &:first-child {
      margin-left:12px;
    }
  }
}

.addon-no-border {
  border:0;
  padding-left:0;
}

label.big-label {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
  line-height: 22px;
  font-weight: 500;
  color: #455a64;
  padding-bottom: 10px;
  padding-top: 10px;
}
.main-container-suivi-commercial {
  margin-left:-10px;

  .actions-commerciales-container {
    border-left:14px solid #f6f9fa;
    position:relative;
    display: flex;
    flex-wrap: wrap;
    padding-top:18px;
    margin-top:-20px;

    small {
      font-size:14px;
      color:$txt-chart;
      font-weight:300;
    }

    &::before {
      content:'';
      width: 0;
      height: 0;
      position:absolute;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 38px solid #f6f9fa;
      top:-36px;
      left:-25px;
      z-index:999;
    }
  }
}

.container-suivi-commercial {
  .panel-group {
    position:relative;

    small.label {
      color:$bleu;
    }

    .vertical-date {
      display: inline-block;
      transform:rotate(-90deg);
      position:absolute;
      font-size:10px;
      opacity:0.8;
      left:-47px;
      top:18px;
    }
  }
  .panel-heading {
    display:flex;
    justify-content:space-between;
    align-items: center;

    .panel-title {
      width:100%;
    }

    .field-actions {
      display:flex;
      align-items:center;

      .btn {
        &:first-childÂ {
          margin-right:4px;
        }
      }
    }

    .panel-title {
      a {
        display:inline-block;
        width:100%;
        color:#455a64 !important;
        transition:0.2s all ease;
        font-weight:300;

        &:hover {
          color:$bleu !important;
        }

        small {
          font-size:12px;
          font-weight:300;
        }
      }
    }
  }
}

.tag-commercial {
  display:flex;
  cursor:pointer;
  justify-content:space-between;
  width:100%;
  padding:12px 10px;
  font-size:16px;

  i {
    font-size:22px;
    vertical-align: middle;
  }
}

.overflow-visible {
  overflow:visible !important;
}

.btn-danger-o {
  margin-left:4px;
  color:$rouge !important;
  background:transparent !important;
  border:1px solid $rouge !important;
  transition:0.4s all ease;

  &:hover {
    opacity:1 !important;
    color:white !important;
    background:$rouge !important;
  }
}

.cog-right-container {
  .box.box-primary {
    padding:10px;
  }
}

.cke_button_icon.fa {
  font-family: FontAwesome !important;
  font-size:19px;
}

.avenants-container {
  h4 {
    color:#455a64;
    font-family:"Montserrat", sans-serif;
    font-size:14px !important;

    span {
      font-size:12px;
      font-weight:300;
    }
  }
}

.ecriture-block-top {
  border-top:2px solid $bleu !important;
}

.sonata-ba-list tbody tr:first-child .ecriture-block-top {
  border-top:1px solid #dee2e6 !important;
}

.global-search-container {
  align-items:center;
  display:flex;
  width: calc(100% - 440px);

  .form-control {
    width:100%;
    border-top:0;
    border-left:0;
    border-right:0;
    position:relative;
  }

  .loader {
    content:'';
    display:block;
    position:absolute;
    right: 290px;
    width: 24px;
    top: 24px;
    z-index:2;
    height:24px;
    background:url('autocomplete-loader.svg') no-repeat;
    background-size:contain;
  }
}

.global-search-autocomplete {
  &.ui-autocomplete {
    max-height:60vh;
    overflow-y:auto;
    overflow-x:hidden;

    .autocomplete-item {
      display:flex !important;
      justify-content:space-between !important;
      align-items:center !important;
      font-weight: 300 !important;
      color: rgba(0, 0, 0, 0.9) !important;
      border-radius:0 !important;
      font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;

      &.projet-actif {
        color: #20aee3 !important;
      }
    }
  }

  &.ui-autocomplete .ui-menu-item {
    //margin-bottom: 0px !important;

    a {
      margin-top:1px !important;
      border-top:1px solid #f3f1f1;
      border-radius:0 !important;
      padding-left:18px !important;
    }
  }

  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    border: 1px solid rgb(32, 174, 227) !important;
    border-radius: 0;
    background: rgba(32, 174, 227, 0.1) !important;
    animation: all 0.2s ease;
  }

  &.ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content.ui-corner-all {
    z-index:9999;
    display: block;
    top: 72px !important;
    left: 259px !important;
    border-radius: 0px;
    width: calc(100% - 477px);
    border: 1px solid #eff0f1;
    border-top: 1px solid white;
    padding:0;
  }
}

.classic-autocomplete {
  &.ui-autocomplete {
    max-height:300px;
    overflow-y:auto;
    overflow-x:hidden;
    border-radius:0 !important;
    border-color: #dee2e6 !important;
    font-weight: 300 !important;
    color: rgba(73, 80, 87, 0.8) !important;
    border-radius:0 !important;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .ui-menu .ui-menu-item a {
    color: rgba(73, 80, 87, 0.9) !important;
  }

  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    background:#20ade31c !important;
    border-radius:0 !important;
    border:0 !important;
    margin:0 !important;
  }
}

.sidebar-collapse {
  .global-search-autocomplete {
    &.ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content.ui-corner-all {
      left: 65px !important;
      width: calc(100% - 283px);
    }
  }
}

.btn-orange-edit, .btn-purple-edit {
  background:$orange;
  border:0 !important;
  border-radius:4px;
  color:white !important;
  transition:0.4s all ease;

  &:hover {
    opacity:0.75;
  }
}

.btn-purple-edit {
  background:$purple;
}

.btn-group-cerebro {
  .btn-default {
    background: white !important;
    border-color: #ddd !important;

    &:hover {
      color: #23527c !important;
      background-color: #eee !important;
      background-image: none;
    }
  }

  .btn-primary {
    background: #20aee3 !important;
    border: 1px solid #20aee3 !important;
    color: white !important;
  }
}

.btn-widget-ticket {
  .btn-default {
    background: white !important;
    border: 1px solid #ddd !important;
    color:#444 !important;

    &:hover {
      color: #23527c !important;
      background-color: #eee !important;
      background-image: none;
    }
  }
}

#webmas-table_filter {
  margin-top:-40px !important;
}

.fa-linear::before {
  content: "\E867";
  font-family: Linearicons-Free;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
}

.break-all {
  word-break:break-all;
}

[id^="dossier-btn-"], #facture-card-header {
  .btn-toggle {
    background:#20aee3 !important;
  }
}

#facture-card-header {
  .btn {
    background:#20aee3 !important;
  }
}

.route-admin_app_individus_list {
  form > .box.box-primary {
    min-width:1450px;
  }
}

.route-factures_ecritures_list {
  .content-wrapper {
    .box.box-primary {
      min-width:1450px;
      background:white;
    }
  }
}

.tablesaw-columntoggle-popup .tablesaw-btn-group>label {
  display:flex;
  justify-content:flex-start;
  padding:0 !important;
  align-items:center;
  margin-bottom: -4px !important;
  font-weight: 400 !important;
  color: #67757c !important;
  font-family: "Montserrat", sans-serif !important;
}

.min-width-200 {
  min-width:200px;
}

.tablesaw-columntoggle-popup .tablesaw-btn-group>label input {
  height:26px !important;
}

.autcomplete_wrappe_main_create_project {
  display:flex;

  .field-actions {
    margin-top:0 !important;

   .sonata-ba-action {
     background-color:#20aee3 !important;
     margin-left:8px !important;
   }
  }
}

.route-utilisateurs_edit, .route-utilisateurs_create {
  .sonata-ba-form {
    box-shadow:none !important;
  }

  .nav-tabs-custom {
    box-shadow: 0 3px 12px rgba(0, 0, 0, .04);
  }
}

.step-progress-container {
  margin-bottom:24px;
  margin-top:4px;
  min-height:200px;
}

ol.steps-progress {
  $line_height: 20px;
  $padding: 9px;
  $arrow_size: ($line_height + 2 * $padding)/2;
  $max_li: 30;

  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: $line_height;
  font-weight: 400;
  margin-bottom:8px;
  counter-reset: li;

  li {
      list-style-image: none;
      list-style-type: none;
      margin-left: 0;
      text-align: center;
      color: #3a3a3a;
      background: #e3e8ea;
      position: relative;
      margin-left: 5px + 2 * $arrow_size;

      &:after {
        position: absolute;
        top: 50%;
        left: 100%;
        content: " ";
        height: 0;
        width: 0;
        pointer-events: none;
        border: solid transparent;
        border-left-color: #e3e8ea;
        border-width: $arrow_size;
        margin-top: -$arrow_size;
      }

      &:first-child {
        margin-left: 0;
        span {
          padding: $padding;
          &:after {
            border: none;
          }
        }
      }

      &:last-child {
        &:after {
          border-width: 0;
        }
      }

      span {
        display: block;
        padding: $padding ($padding + $arrow_size) $padding $padding;

        &:after {
          position: absolute;
          top: 50%;
          right: 100%;
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border: solid #e3e8ea;
          border-left-color: transparent;
          border-width: $arrow_size;
          margin-top: -$arrow_size;
        }

        &:before {
          //content: counter(li) ") ";
          counter-increment: li;
        }
      }
    }

    & > li {
        float: left;
    }

    li.current {
        color: #fff;
        background: #2489c5 !important;

        &:after {
          border-left-color: #2489c5 !important;
        }

        span {
            &:after {
                border-color: #2489c5 !important;
                border-left-color: transparent !important;
            }
        }
    }

    @for $i from 1 through $max_li {
        li.step#{$i} {
            z-index: $max_li - $i;
            cursor:pointer;

            &.disabled {
              opacity:0.6;
              cursor:not-allowed;
            }

            &.activated {
              background:#83cae4;
              color:white;

              &:after {
                border-left-color: #83cae4;
              }

              span {
                  &:after {
                      border-color:#83cae4;
                      border-left-color: transparent;
                  }
              }
            }

            &.stripedÂ {
              background-image: linear-gradient(140deg, #545454 3.13%, rgba(0, 0, 0, 0) 3.13%, rgba(0, 0, 0, 0) 50%, #545454 50%, #545454 53.13%, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0) 100%);
              background-size: 24.89px 20.89px;
            }
        }
    }
}

.table-analytique {
  .add-action-container {
    .btn-add {
      display:block;
      width:100%;
      height:100%;
      box-shadow:none;
      color:rgba(0, 0, 0, 0.24) !important;
      font-weight:600;
      font-size:24px;
      padding-bottom:20px;
      padding-top:18px;
      transition:all 0.3s ease;

      &:hover {
        color:rgba(0, 0, 0, 0.4) !important;
      }
    }
  }
}

.no-padding-box-body {
  .box-body {
    padding:0 !important;
  }
}

.avenants-container {
  .sonata-ba-form-actions {
    display:none;
  }
}

.nav.navbar-top-links.breadcrumb {
  @media screen and (max-width: 1720px) {
    li.active {
      vertical-align: top;

      &::before {
        vertical-align: top;
      }

      span {
        max-width:28vw;
      }
    }
  }
}

.btn-big-square {
  display:inline-block;
  width: 100%;
  margin-bottom: 8px;
  border: 0;
  background: $bleu;
  color: white;
  padding: 16px 0;
  font-size: 17px;
  transition:0.3s all ease;
  outline:none !important;

  &.disabled {
    background:$gris !important;
    cursor:not-allowed;
    opacity:0.8;
  }

  &.vert {
    background:$vert;
  }
  &.purple {
    background:$purple;
  }
  &.rouge {
    background:$rouge;
  }
  &.orange {
    background:$orange;
  }

  span {
    font-size:34px;
  }

  &:hover {
    opacity:0.8;
  }
}

tr.disabled-line {
  opacity:0.6;
}

.navigation {
  margin-top:12px;
  & > .pagination {
    &.justify-content-center {
      display:flex;
      justify-content:center;
    }
  }
}

#create-facture-modal,
#create-facture-modal-2,
#create-facture-modal-3,
#print-bdco-modal,
#print-devis-modal {
  overflow-y:auto !important;
}

table.dataTable thead .sorting{background-image:url("sort_both.png")}
table.dataTable thead .sorting_asc{background-image:url("sort_asc.png")}
table.dataTable thead .sorting_desc{background-image:url("sort_desc.png")}
table.dataTable thead .sorting_asc_disabled{background-image:url("sort_asc_disabled.png")}
table.dataTable thead .sorting_desc_disabled{background-image:url("sort_desc_disabled.png")}

table .ui-state-highlight {
  background-color:rgba(210, 188, 36, 0.2) !important;
  height:120px;

  td:first-child { display:none !important; }
  td:nth-child(2) { display:none !important; }
  td:nth-child(3) { display:none !important; }
  td:nth-child(4) { display:none !important; }
  td:nth-child(5) { display:none !important; }
  td:nth-child(6) { display:none !important; }
  td:nth-child(7) { display:none !important; }
  td:nth-child(8) { display:none !important; }
  td:nth-child(9) { display:none !important; }
  td:nth-child(10) { display:none !important; }
}

#collapse-bdco table .ui-state-highlight {
  background:rgba(210, 188, 36, 0.2) !important;
  height:120px;

  td:first-child { display:none !important; }
  td:nth-child(2) { display:none !important; }
  td:nth-child(3) { display:table-cell !important; }
  td:nth-child(4) { display:table-cell !important; }
  td:nth-child(5) { display:table-cell !important; }
  td:nth-child(6) { display:table-cell !important; }
  td:nth-child(7) { display:table-cell !important; }
  td:nth-child(8) { display:table-cell !important; }
  td:nth-child(9) { display:table-cell !important; }
  td:nth-child(10) { display:table-cell !important; }
}

div[id^="collapse-avenant-"] table .ui-state-highlight {
  background:rgba(210, 188, 36, 0.2) !important;
  height:120px;

  td:first-child { display:none !important; }
  td:nth-child(2) { display:none !important; }
  td:nth-child(3) { display:table-cell !important; }
  td:nth-child(4) { display:table-cell !important; }
  td:nth-child(5) { display:table-cell !important; }
  td:nth-child(6) { display:table-cell !important; }
  td:nth-child(7) { display:table-cell !important; }
  td:nth-child(8) { display:table-cell !important; }
  td:nth-child(9) { display:table-cell !important; }
  td:nth-child(10) { display:table-cell !important; }
}

.disable-internal-box {
  .box.box-primary {
    box-shadow: none !important;
  }
}

.line-height-a-btn {
  .btn {
    margin-bottom:4px;
  }
}

.lien-changelog {
  color:#666 !important;

  &:hover {
    text-decoration:underline;
  }
}

.select2-100 {
  & > .select2-container {
    width:100%;
  }
}
